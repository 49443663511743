import { render, staticRenderFns } from "./ViewMyAccount.vue?vue&type=template&id=265cb5f0&scoped=true&"
import script from "./ViewMyAccount.vue?vue&type=script&lang=ts&"
export * from "./ViewMyAccount.vue?vue&type=script&lang=ts&"
import style0 from "./ViewMyAccount.vue?vue&type=style&index=0&id=265cb5f0&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "265cb5f0",
  null
  
)

export default component.exports