

























































































import { Component, Ref, Vue } from 'vue-property-decorator'
import { User } from '@/model/User'
import { ROLES } from '@/model/Role'
import UserFormCard from '../../components/core/user/UserFormCard.vue'
import UserDetails from '../../components/core/user/UserDetails.vue'
import { Utils } from '../../utils/Utils'
import { DigInputText } from '@digithia/input/text'

export type ToggableComponent = Vue & { toggle: (toogled: boolean) => void }
export type CancelableComponent = Vue & { cancel: (noEvent: boolean) => void }

@Component({
  components: { UserFormCard, UserDetails },
})
export default class ViewMyAccount extends Vue {
  @Ref('userDetails') readonly userDetailsEl!: any
  @Ref('editUserForm') readonly editUserFormEl!: CancelableComponent

  get canDelete(): boolean {
    return this.passphrase === this.$t('deleteAccountPassphrase')
  }

  get user(): User {
    return this.$store.getters.loggedUser
  }

  passphrase = ''
  password = ''

  currentPassword = ''
  newPassword = ''
  newPasswordConf = ''

  get isAdmin() {
    return this.user.role === ROLES.ADMIN
  }

  updateUser(user: User) {
    this.$store.dispatch('updateOwnData', { user: user }).then(json => {
      this.$store.dispatch('toaster/toast', json)
      if (json.success) {
        this.userDetailsEl.editing = false
      }
    })
  }

  cancelForm() {
    this.editUserFormEl.cancel(true)
  }

  cancelDelete() {
    this.passphrase = ''
    this.password = ''
  }

  deleteAccount() {
    this.$store.dispatch('deleteOwnAccount', this.password).then(json => {
      this.$store.dispatch('toaster/toast', json)
      if (json.success) {
        this.password = ''
        this.$store.dispatch('logout')
        this.$router.push('/login')
      }
    })
  }

  mounted() {
    const inputPassword = document.querySelector(
      '#new-password',
    ) as DigInputText
    inputPassword.validator = (value: string) => {
      return !!value && value.length > 4
    }
    inputPassword.errors.validator = `Le mot de passe est invalide : minimum 5 caractères`
    const inputConfirmation = document.querySelector(
      '#new-password-conf',
    ) as DigInputText
    inputConfirmation.validator = (value: string) => {
      return value === this.newPassword
    }
    inputConfirmation.errors.validator = `La confirmation ne correspond pas au mot de passe`
  }

  changePassword() {
    const valid = Utils.checkInputsValidity('.change-password dig-input-text')

    if (valid) {
      this.$store
        .dispatch('changePassword', {
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
        })
        .then(json => {
          this.$store.dispatch('toaster/toast', json)
          if (json.success) {
            this.currentPassword = ''
            this.newPassword = ''
            this.newPasswordConf = ''
            Utils.resetInputsValidity('.change-password dig-input-text')
          }
        })
    } else {
      this.$store.dispatch('toaster/toast', {
        type: 'warning',
        message: `Le formulaire est invalide`,
      })
    }
  }
}
